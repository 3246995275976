import React from "react";
import CommingSoonSection from "./section-components/comming-soon";

const CommingSoon = () => {
  return (
    <div>
      <CommingSoonSection />
    </div>
  );
};

export default CommingSoon;
